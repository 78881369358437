import React from "react";
import Header from "@Components/Header/Header"
import JobListingPage from "@Components/JobListing/JobListing"


import FooterPage from "@Components/Footer/Footer"

const JobListing = (props) => {
   return (
      <div className="wrapper slide">
         <div className="joblisting-page-header"><Header nobanner={true} /></div>
         {/* <JobListingPage /> */}
         <FooterPage />

      </div>
   )
};
export default JobListing